import { Injectable } from '@angular/core';

import { Message } from "./message.model";

@Injectable({
  providedIn: 'root'
})
export class MessagesService {
  private handler: ((message: Message) => void) | null = null;

  reportMessage(message: Message) {
    if (this.handler != null) {
      this.handler(message);
    }
  }

  registerMessagesHandler(handler: (message: Message) => void) {
    this.handler = handler;
  }
}
