<nav class="navbar navbar-dark sticky-top flex-md-nowrap p-0 shadow-sm">
  <!-- Brand -->
  <a class="navbar-brand col-md-3 col-lg-2 mr-0 px-3" routerLink="/">
    <img src="assets/images/iraten-logo-gray.png" height="30" alt="{{ brandName }}"> 
  </a>

  <!-- Sidebar Toggler -->
  <button type="button" class="navbar-toggler position-absolute d-md-none"
    data-toggle="collapse" data-target="#sidebarMenu"
    aria-controls="sidebarMenu" aria-expanded="false" aria-label="Toggle navigation"
  >
    <span class="navbar-toggler-icon"></span>
  </button>

  <a class="nav-link dropdown-toggle arrow-none" data-bs-toggle="dropdown" href="#" role="button" aria-haspopup="false" aria-expanded="false">
      <span class="account-user-avatar">
        <img src="assets/images/metropolenice.png" alt="user-image" class="rounded-circle">
      </span>
      <span class="account-user-name">Metropole Nice</span>
  </a>
</nav>
