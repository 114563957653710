import { Component } from '@angular/core';

import { MessagesService } from "./messages.service";
import { Message } from "./message.model";

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss']
})
export class MessageComponent {
  lastMessage: Message | null = null;

  constructor(messagesService: MessagesService) {
    messagesService.registerMessagesHandler(message => this.lastMessage = message);
  }
}
