<nav id="sidebarMenu" [ngClass]="'sidebar d-md-block bg-light collapse ' + classes">
  <div class="sidebar-sticky pt-3">
    <ul class="nav flex-column">
      <li class="nav-item">
        <a class="nav-link" routerLink="/purchases/dashboard" routerLinkActive="active">
          <i class="bi bi-house"></i>
          Tableau de bord
        </a>
      </li>

      <li class="nav-item">
        <a class="nav-link d-flex" href="#purchasesMenu" role="button" data-toggle="collapse" aria-controls="purchasesMenu"
          [attr.aria-expanded]="purchaseOrdersLinkActive.isActive
            || purchaseInvoicesLinkActive.isActive
            || purchasesProductsLinkActive.isActive
            || purchasesSuppliersLinkActive.isActive
          "
          [class.active]="purchaseOrdersLinkActive.isActive
            || purchaseInvoicesLinkActive.isActive
            || purchasesProductsLinkActive.isActive
            || purchasesSuppliersLinkActive.isActive
          "
        >
          <i class="bi bi-cart3"></i>&nbsp;
          Achats
          <i class="bi bi-chevron-up ml-auto"></i><i class="bi bi-chevron-down ml-auto"></i>
        </a>
        <ul id="purchasesMenu" class="list-unstyled collapse"
          [class.show]="purchaseOrdersLinkActive.isActive
            || purchaseInvoicesLinkActive.isActive
            || purchasesProductsLinkActive.isActive
            || purchasesSuppliersLinkActive.isActive
          "
        >
          <li class="nav-item pl-4">
            <a class="nav-link" routerLink="purchases/purchase-orders"
              routerLinkActive="active" #purchaseOrdersLinkActive="routerLinkActive"
            >
              Bons de commande
            </a>
          </li>
          <li class="nav-item pl-4">
            <a class="nav-link" routerLink="purchases/purchase-invoices"
              routerLinkActive="active" #purchaseInvoicesLinkActive="routerLinkActive"
            >
              Factures
            </a>
          </li>
          <li class="nav-item pl-4">
            <a class="nav-link" routerLink="/purchases/products"
              routerLinkActive="active" #purchasesProductsLinkActive="routerLinkActive"
            >
              Produits
            </a>
          </li>
          <li class="nav-item pl-4">
            <a class="nav-link" routerLink="/purchases/suppliers"
              routerLinkActive="active" #purchasesSuppliersLinkActive="routerLinkActive"
            >
              Fournisseurs
            </a>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</nav><!-- /.sidebar -->
