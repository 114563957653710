<app-navbar brandName="Oriade"></app-navbar>
<div class="container-fluid">
  <div class="row">
    <app-sidebar classes="col-md-3 col-lg-2"></app-sidebar>
    <main role="main" class="col-md-9 ml-sm-auto col-lg-10 px-md-4">
      <app-message></app-message>
      <router-outlet></router-outlet>
    </main>
  </div><!-- /.row -->
</div><!-- /.container-fluid -->
